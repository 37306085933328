<template>
  <div class="w-full flex flex-col">
    <div class="flex-1 block m-0 md:m-2 p-0 md:p-2 border border-black left fixed w-full sm:w-auto">
      <h1 class="text-3xl font-normal leading-normal mt-0 mb-2 m-1 p-1">RSVP Reader</h1>

      <div class="block m-1 p-1 flex" v-bind:class="{ hidden: isHidden }">
        <div class="flex-auto">
          {{ wpm }} WPM
        </div>

        <div class="flex-auto">
          {{ timeInterval }} ms interval
        </div>

        <div class="flex-auto">
          {{ wordNumber }}/{{ wordCount }} words
        </div>

        <div class="flex-auto">
          {{ percentage }}%
        </div>

      </div>

      <div class="block m-1 p-1" v-bind:class="{ hidden: isHidden }">
        <select @change="dropdownBook($event)" class="border border-black border-2 p-1 w-full">
          <option v-for="(book, index) in books" :value="book.filename" :key="index">
            {{ book.title }}
          </option>
        </select>
      </div>

      <div class="mt-2 mb-8 p-1 flex w-full">

        <div class="flex-auto" v-bind:class="{ hidden: isHidden }">
          <div class="inline-block">
            <label for="wpm">WPM</label>
            <input type="number" id="wpm" v-model="wpm" @change="convert_wpm_to_ms()" class="w-16 border border-gray-600 ml-1 mr-2 px-1" />
          </div>

          <div class="inline-block">
            <label for="word">WORD</label>
            <input type="number" id="word" v-model="wordNumber" @change="updateBookmark()" class="w-20 border border-gray-600 ml-1 px-1" />
          </div>

        </div>


        <div class="flex-auto flex flex-row" v-bind:class="{ 'justify-center': isHidden }">
          <button @click="clickPlay" v-if="!loading" class="mx-1 px-1"><unicon name="play" height="22" width="22"></unicon></button>
          <button @click="clickPause" v-if="!loading" class="mx-1 px-1"><unicon name="pause" height="22" width="22"></unicon></button>
          <button @click="clickStop" v-if="!loading" class="mx-1 px-1"><unicon name="square" height="20" width="20"></unicon></button>
        </div>

      </div>

      <div class="block absolute bottom-0 left-1">
        <button v-on:click="isHidden = !isHidden">{{ isHidden ? 'expand' : 'min' }}</button>
      </div>

      <div class="block absolute bottom-0 right-1" v-bind:class="{ hidden: isHidden }">
        by <a href="//shortdark.co.uk" rel="noopener" target="_blank" class="underline">shortdark.co.uk</a>
      </div>


    </div>


    <div class="flex h-screen">
      <div class="m-auto overflow-hidden">
        <div class="text-6xl">{{ selectedWord }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      books: [],
      txtfile: 'pg1125-william-shakespeare--alls-well-that-ends-well.txt',
      dbook: '',
      bookArray: [],
      wordCount: 0,
      selectedWord: '',
      play: false,
      wordNumber: 0,
      wpm: 200,
      timeInterval: 0,
      timer: '',
      loading: true,
      percentage: 0,
      domain: 'https://api.rsvpreader.com',
      isHidden: false
    }
  },
  methods: {
    loadJSON(callback) {
      let xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open('GET', this.domain + '/books/', true)
      xobj.onreadystatechange = function () {
        if (xobj.readyState === 4 && xobj.status === 200) {
          callback(xobj.responseText);
        }
      };
      xobj.send(null);
    },
    init() {
      let that = this
      that.loadJSON(function (response) {
        that.books = JSON.parse(response)
      });
      this.displayBook()
    },
    dropdownBook(e) {
      this.txtfile = e.target.value
      this.wordNumber = 0
      this.wordCount = 0
      this.loading = true
      this.percentage = 0
      this.selectedWord = ''
      this.play = false
      this.displayBook()
    },
    displayBook() {
      this.axios
          .get(this.domain + '/s3/'+this.txtfile)
          .then((response)=>{
            this.dbook = response.data
            this.dbook = this.dbook.replace(/\n/g, ' ')
                .replace(/\s\s+/g, ' ');
            this.bookArray = this.dbook.trim().split(" ");
            this.bookArray = [''].concat(this.bookArray);
            this.wordCount = this.bookArray.length;
            this.loading = false
            this.convert_wpm_to_ms();
            this.startTimer();
          }).catch(error => console.error(error));
    },
    convert_wpm_to_ms(){
      this.timeInterval = 60000 / this.wpm;
      clearInterval(this.timer);
      this.startTimer();
    },
    startTimer() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.play === true) {
          this.wordNumber++;
          this.selectedWord = this.bookArray[this.wordNumber];
          this.percentage = ((this.wordNumber / this.wordCount) * 100).toFixed(2);
        }
      },this.timeInterval);
    },
    updateBookmark() {
      this.selectedWord = this.bookArray[this.wordNumber];
      this.percentage = ((this.wordNumber / this.wordCount) * 100).toFixed(2);
    },
    clickPlay() {
      this.play = true
      if (this.play === true) {
        this.startTimer();
      }
    },
    clickPause() {
      this.play = false
      clearInterval(this.timer);
    },
    clickStop() {
      this.play = false
      this.wordNumber = 0
      this.selectedWord = this.bookArray[this.wordNumber];
      this.percentage = 0
      clearInterval(this.timer);
    }
  },
  mounted() {
    this.init()
  }
}
</script>