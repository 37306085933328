/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App'
import './main.css'

window.Vue = require('vue');


//Vue.component('app', require('./App.vue').default);
/*const app = Vue.createApp({
    el: "#app"
});*/

import Unicon from 'vue-unicons'
import { uniPlay, uniPause, uniSquare } from 'vue-unicons/dist/icons'

Unicon.add([uniPlay, uniPause, uniSquare])


const myApp = createApp(App)
myApp.use(VueAxios, axios)
    .use(Unicon, {fill: 'black'});
myApp.mount('#app')

